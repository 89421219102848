import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';

export const DASHBOARD = '/dashboard';

export function featureGuard(key: keyof AuthorizationService): CanActivateFn {
  return () => {
    const router = inject(Router);
    const feature = inject(AuthorizationService)[key].asObservable();
    return feature.pipe(map(value => value || router.parseUrl(DASHBOARD)));
  }
}
